import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Account from './account'

import './header.scss'

const Header = ({ siteTitle }) => {
  const isActive = ({ isPartiallyCurrent }) => (isPartiallyCurrent ? { className: 'active' } : false)
  return (
    <header className='top'>
      <div>
        <div>
          <Link to="/"><div className='account-image'><Account /></div></Link>
        </div>
        <div className='menu'>
          <Link to='/about' getProps={isActive}>About</Link>
          <Link to='/works' getProps={isActive}>Works</Link>
          <Link to='/blog' getProps={isActive}>Blog</Link>
          <Link to='/contact' getProps={isActive}>Contact</Link>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
